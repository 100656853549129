"use client"
import Image from 'next/image'
import logo from "@/app/img/logo.png"
import { HiOutlineMenuAlt3 } from "react-icons/hi"
import AccountSection from './AccountSection';

function Header({ background_color, account_section, student }) {
  return (
    <main>
      <nav className={`${background_color} fixed top-0 ease-in w-full z-50 dark:bg-gray-800 dark:border-gray-700 border-b-2`}>
        <div className=' max-w-full flex items-center justify-between'>
          <a href="/">
            <Image src={logo} alt='logo' className='p-4 lg:w-52 lg:pl-14 sm:p-4' />
          </a>
          {/* <HiOutlineMenuAlt3 className='lg:hidden block text-4xl ' /> */}

          <AccountSection className={account_section ? 'block' : 'hidden'} student={student} />
        </div>
      </nav>
      <div>
        {/* <Profile /> */}
      </div>
    </main>
  )
}

export default Header