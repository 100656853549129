import(/* webpackMode: "eager" */ "C:\\Users\\user\\OneDrive\\Documents\\Document\\TNM\\tnm_portal\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "C:\\Users\\user\\OneDrive\\Documents\\Document\\TNM\\tnm_portal\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.jsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "C:\\Users\\user\\OneDrive\\Documents\\Document\\TNM\\tnm_portal\\node_modules\\react-toastify\\dist\\react-toastify.esm.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\user\\OneDrive\\Documents\\Document\\TNM\\tnm_portal\\src\\app\\component\\header.jsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\user\\OneDrive\\Documents\\Document\\TNM\\tnm_portal\\src\\app\\globals.css");
;
import(/* webpackMode: "eager" */ "C:\\Users\\user\\OneDrive\\Documents\\Document\\TNM\\tnm_portal\\node_modules\\react-toastify\\dist\\ReactToastify.css");
